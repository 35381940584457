body{
    background-image: url('D:/ReactJS/AI - Copy/ai/public/images/10 - Copy.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.textcontainer{
    width: 90%;
    margin: auto;
    top: 70px;
    margin-top: 70px;
    justify-content: center;
    text-align: justify;
    font-family: 'Montserrat';
      }

.headings{
  font-size: 33px;
}
.subheadings{
  font-size: 25px;
}
.italic1{
  font-style: italic;
  font-size: 18px;
}
.fword{
  font-weight: 600;
  font-size: 30px;
}
.topspace1{
  margin-top: 6px;
}

  /* whatspp button starts*/
  .whatsapp{
    position:relative;
    top:5px;
    height:50px;
    width:150px;
    margin:auto;
    margin-bottom: 70px;
    
    }
    .whatsappus{
    height:40px;width:140px;
    }
  /* whatspp button ends*/    

  @media (max-width: 367px) {
    body {
      overflow-x: hidden;
    }

  }

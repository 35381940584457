#videobox{
  height: auto;
  width: 400px;
  margin: auto;
  overflow: hidden;
  
  }
  #myVideo{
      width: 100%;
      height: 100%;
  }
.textcontainer{
    width: 80%;
    margin: auto;
    top: 70px;
    margin-top: 70px;
    justify-content: center;
    text-align: justify;
    font-family: 'Montserrat';
      }

.textcontainer1{
        font-weight: bolder;
        font-style: italic;
        width: 50%;
        margin: auto;
        font-family: 'Montserrat';
        line-height: 100px;
        height: 100px;
        text-align: center;
          }
.textcontainer1 p{
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.headings{
  font-size: 33px;
}
.subheadings{
  font-size: 25px;
}
ul.b {
  list-style-type: circle;
}

  /* whatspp button starts*/
  .whatsapp{
    position:relative;
    top:5px;
    height:50px;
    width:150px;
    margin:auto;
    margin-bottom: 70px;
    
    }
    .whatsappus1{
    height:40px;width:70px;
    }
  /* whatspp button ends*/    

  @media (max-width:684px) {
    body{
        overflow-x: hidden;
    }
    #videobox{
      height: auto;
      width: 300px;
      margin: auto;
      overflow: hidden;
      
      }
    .textcontainer1{
      font-weight: bolder;
      font-style: italic;
      width: 74%;
      margin: auto;
      font-family: 'Montserrat';
      line-height: 100px;
      height: 155px;
      text-align: center;
        }
  }

  @media (max-width: 367px) {
    body {
      overflow-x: hidden;
    }
    #videobox{
      height: auto;
      width: 250px;
      margin: auto;
      overflow: hidden;
      
      }
    .textcontainer1{
      font-weight: bolder;
      font-style: italic;
      width: 88%;
      margin: auto;
      font-family: 'Montserrat';
      line-height: 100px;
      height: 160px;
      text-align: center;
        }
  }

  @media (max-width: 367px) {
    body {
      overflow-x: hidden;
    }
    #videobox{
      height: auto;
      width: 220px;
      margin: auto;
      overflow: hidden;
      
      }
    .textcontainer1{
      font-weight: bolder;
      font-style: italic;
      width: 88%;
      margin: auto;
      font-family: 'Montserrat';
      line-height: 100px;
      height: 160px;
      text-align: center;
        }
  }

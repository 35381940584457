.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  height: 180px;
  background-color: white;
  color: green;
  display: flex;
  font-family: 'Nunito';
  font-weight: bolder;
  /* background-color: red; */
}

.middle {
  /* background-color: blue; */
  width: 40%;
  /* margin-left:85px; */
  margin-top: 7px;
  padding-top: 5px;
  /* float:left; */
  width: 40%;
  /* display: block; */
  text-align: left;
  font-size: 15px;
  margin-left: 140px;
}

.left1 {
  /* background-color: aqua; */
  margin-left: 25px;
  margin-top: 12px;
  float: left;
  width: 40%;
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 17px;
}

.right1 {
  margin-right: 65px;
  margin-top: 12px;
  float: right;
  width: 55%;
  display: flex;
  justify-content: right;
  align-items: right;
  font-size: 16px;

}

.address {
  word-spacing: 3px;
}

.icon1 {
  height: 35px;
}

/* footer ends*/

@media (max-width: 916px) {

  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 180px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
  
  .middle {
    /* background-color: blue; */
    width: 40%;
    /* margin-left:85px; */
    margin-top: 7px;
    padding-top: 5px;
    /* float:left; */
    width: 40%;
    /* display: block; */
    text-align: left;
    font-size: 15px;
    margin-left: 140px;
  }
  
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 17px;
  }
  
  .right1 {
    margin-right: 65px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 17px;
  
  }
  
  .address {
    word-spacing: 3px;
  }
  
  .icon1 {
    height: 35px;
  }

}

@media (max-width: 855px) {

  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 180px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
  
  .middle {
    /* background-color: blue; */
    width: 40%;
    /* margin-left:85px; */
    margin-top: 7px;
    padding-top: 5px;
    /* float:left; */
    width: 40%;
    /* display: block; */
    text-align: left;
    font-size: 15px;
    margin-left: 140px;
  }
  
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 8px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 17px;
  }
  
  .right1 {
    margin-right: 35px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 16px;
  
  }
  
  .address {
    word-spacing: 3px;
  }
  
  .icon1 {
    height: 35px;
  }

}


@media (max-width: 768px) {

  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 190px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
}


@media (max-width: 638px) {
  body {
    overflow-x: hidden;
  }


}

@media (max-width: 551px) {
  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 180px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
  
  
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 13px;
  }
  
  .right1 {
    margin-right: 25px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 13px;
  
  }
  
  .address {
    word-spacing: 3px;
  }
  
  .icon1 {
    height: 35px;
  }


}

@media (max-width: 481px) {
  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 160px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
  
  
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 13px;
  }
  
  .right1 {
    margin-right: 25px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 13px;
  
  }
  
  .address {
    word-spacing: 3px;
  }
  
  .icon1 {
    height: 35px;
  }

}

@media (max-width: 451px) {
  body {
    overflow-x: hidden;
  }
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 12px;
  }
  
  .right1 {
    margin-right: 15px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 11px;
  
  }
  
}
@media (max-width: 371px) {
  body {
    overflow-x: hidden;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 135px;
    background-color: white;
    color: green;
    display: flex;
    font-family: 'Nunito';
    font-weight: bolder;
    /* background-color: red; */
  }
  
  .left1 {
    /* background-color: aqua; */
    margin-left: 25px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 10px;
  }
  
  .right1 {
    margin-right: 15px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 10px;
  }

}

@media (max-width: 301px) {
  body {
    overflow-x: hidden;
  }
  .left1 {
    /* background-color: aqua; */
    margin-left: 15px;
    margin-top: 12px;
    float: left;
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 9px;
  }
  
  .right1 {
    margin-right: 9px;
    margin-top: 12px;
    float: right;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 9px;
  }

}

  /* services starts*/
  
  .style{
    text-align: center;
    font-size:25px;
    font-family: 'Nunito';
    color: green;
  }
  
.hlink{
  font-size: 18px;
  text-decoration: none;
  color: green;
  }
.hlink:visited{
  color:darkgreen;
}

/* services ends*/
.container1{
  width: 80%;
  margin: auto;
  font-family: 'Nunito';
  /* background-image: url("/public/images/images/clinic images-20240122T082942Z-001/clinicimages/bg.png");
  background-position: center;
  background-size: cover; 
  opacity: 0.8;
  overflow-x:hidden; 
  background-repeat: no-repeat; */
}
.serviceone1{
  position: relative;
  display: inline-block;
}
.text-overlay{
  font-size: 15px;
  position: absolute;
  top:85px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  height: 100px;
  width: auto;
}
.serviceone1:hover .text-overlay{
  opacity: 1;
}

.container1 .serviceone1 img{
  border-radius: 10%;
  height: 280px;
  width: 300px;
}
.rightside{
  float: right;
}
.marginleft{
  margin-left: 25px;
}
.marginlefttext{
  margin-left: 50px;
}

.form-group{
  width: 60%;
  margin: auto;
}
input{
  text-align: center;
}

@media (max-width: 992px){
    body{
    overflow-x:hidden;
    }
    .marginleft{
      margin-left: 0px;
    }
    .rightside{
      float: none;
    }
    .marginlefttext{
      margin-left: 0px;
    }
    .container1 .serviceone1 img{
      height: 150px;
      width: 150px;
    }
}

@media (max-width: 768px){
  body{
  overflow-x:hidden;
  }
  .text-overlay{
    font-size: 8px;
    position: absolute;
    top: 40px;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    height: 60px;
    width: auto;
  }
}

@media (max-width: 567px){
  body{
  overflow-x:hidden;
  }
  .container1{
    text-align: center;
  }
  .container1 .serviceone1 img{
    height: 250px;
    width: 250px;
  } 
}



@media (max-width: 475px){
    body{
    overflow-x:hidden;
    }

}
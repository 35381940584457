body{ 
    /* background-image: url("/public/images/images/clinic images-20240122T082942Z-001/clinicimages/bg.png");
    background-position: center;
    background-size: cover; 
     
    overflow-x:hidden; 
    background-repeat: no-repeat; */
  }

.whatspplogo{
  z-index: 1000;
  float:right;
  top: 250px;
  height:45px;width:45px;position: sticky;
  opacity: 0.8;
}
.whatspplogo:hover{
  opacity: 1;
  cursor: pointer;
}
.col{
  z-index: -1000;
  height: max-content;
}


  
.center{
    height: auto;
    width: auto;
   /* margin-top:100px; */
    margin-bottom: 60px;
    z-index: 100;
    /* background-color: red; */
}
.center .img{
    width: 80%;
}
.whatsappimage{
    margin-top: -70px;
    height: 35px;
    width: 130px;
}
.icon11{
    margin-top: -38px;
    cursor: pointer;
    height: 35px;
}


/* Circles*/
.circles .row{
    width: 80%;
    margin: auto;
}
.one{
    height: 150px;
    width: 150px;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(255,0,0,0.6);
    justify-content: center;
    display: flex;
}
.oneimage{
    margin-top: 15%;
    margin-left: 4%;
    height: 98px;
    width: 65px;
}
.two{
    height: 150px;
    width: 150px;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(255,255,0,0.6);
    justify-content: center;
    display: flex;
}
.twoimage{
    margin-top: 15%;
    margin-left: 1%;
    height: 97px;
    width: 88px;
}
.three{
    height: 150px;
    width: 150px;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(135,206,235,0.6);
    justify-content: center;
    display: flex;
}
.threeimage{
    margin-top: 15%;
    margin-left: 1%;
    height: 97px;
    width: 88px;
}
/* modal starts */
.modal-content{
    width: fit-content; 
    margin: auto;
   }
  .img1{
    height:250px;width:500px;
    }
    .img2{
    height:250px;width:500px;
    }
  /* modal ends*/   
/* Circles*/

/*imageslider starts */
.hh{
    width: auto;
    height: 500px;
}
.jj{
  display: flex;
  justify-content: center;
    width: 900px;
    margin: auto;
    margin-top: 50px;
}
/*imageslider ends */

/* 360 starts*/
.text1{
    text-align:center;
    font-size:25px;
    font-family: 'Nunito';
    }
    .fimage{
    display:block;
    margin:auto;
    width:60%;
    height:400px;
    }
  /* 360 ends*/

@media (max-width:992px){
    body{
        overflow-x: hidden;
    }
    .center{
        height: auto;
        width: auto;
       
        /* background-color: red; */
    }
    .center .img{
        width: 95%;
    }
    .whatsappimage{
        margin-top: 0px;
        height: 36px;
        width: 125px;
    }
    .icon11{
        margin-top: 0px;
        cursor: pointer;
        height: 30px;
    }
        /* modal starts  */
.img1{
    height:150px;width:300px;
    }
    .img2{
    height:150px;width:300px;
    }
  /* modal ends  */ 

/* */
.hh{
    width: 100%;
    object-fit: fill;
    height: 400px;
}
.jj{
    width: 79%;
}
/* */

/* 360 starts  */ 
.fimage{
    width:60%;
    }
  /* 360 ends  */ 


}


@media (max-width:768px) {
    body{
        overflow-x: hidden;
    }
/* Circles*/
.circles .row{
    width: 100%;
    margin: auto;
}
.one{
    height: 130px;
    width: 130px;
    margin: auto;
    border-radius: 50%;
 
}
.oneimage{
    margin-top: 9%;
    margin-left: 4%;
    height: 90px;
    width: 55px;
}
.two{
    height: 130px;
    width: 130px;
    margin: auto;
    border-radius: 50%;
 
}
.twoimage{
    margin-top: 12%;
    margin-left: 0;
    height: 98px;
    width: 65px;
}
.three{
    height: 130px;
    width: 130px;
    margin: auto;
    border-radius: 50%;
 
}
.threeimage{
    margin-top: 12%;
    margin-left: 4%;
    height: 98px;
    width: 65px;
}
/* Circles*/

}

@media (max-width:426px){
    body{
        overflow-x: hidden;
    }
    .center{
        height: auto;
        width: auto;
        margin-bottom: 100px;
        /* background-color: red; */
    }
    .center .img{
        width: 100%;
    }
    .whatsappimage{
        height: 30px;
        width: 120px;
    }

    /* Circles*/
.circles .row{
    width: 90%;
    margin: auto;
}
.one{
    height: 90px;
    width: 90px;
    margin: auto;
    border-radius: 50%;
 
}
.oneimage{
    margin-top: 11%;
    margin-left: 4%;
    height: 55px;
    width: 40px;
}
.two{
    height: 90px;
    width: 90px;
    margin: auto;
    border-radius: 50%;
 
}
.twoimage{
    margin-top: 15%;
    margin-left: 4%;
    height: 60px;
    width: 45px;
}
.three{
    height: 90px;
    width: 90px;
    margin: auto;
    border-radius: 50%;
 
}
.threeimage{
    margin-top: 15%;
    margin-left: 4%;
    height: 60px;
    width: 45px;
}
/* Circles*/
.hh{
    width: 100%;
    object-fit: fill;
    height: 200px;
}
.jj{
    width: 80%;
    margin-top: 20px;
}
/* 360 */
.text1 {
    text-align: center;
    font-size: 20px;
    font-family: 'Nunito';
}
   .fimage{
    width:80%;
    height:300px;
    }
/* 360 */
}




@media (max-width:321px){
    body{
        overflow-x: hidden;
    }
    .center{
        height: auto;
        width: auto;
        margin-bottom: 100px;
        /* background-color: red; */
    }
    .center .img{
        width: 100%;
    }
    .whatsappimage{
        height: 25px;
        width: 100px;
    }

    /* Circles*/
.circles .row{
    width: 90%;
    margin: auto;
}
.one{
    height: 70px;
    width: 70px;
    margin: auto;
    border-radius: 50%;
 
}
.oneimage{
    margin-top: 11%;
    margin-left: 4%;
    height: 45px;
    width: 40px;
}
.two{
    height: 70px;
    width: 70px;
    margin: auto;
    border-radius: 50%;
 
}
.twoimage{
    margin-top: 15%;
    margin-left: 4%;
    height: 45px;
    width: 40px;
}
.three{
    height: 70px;
    width: 70px;
    margin: auto;
    border-radius: 50%;
 
}
.threeimage{
    margin-top: 15%;
    margin-left: 4%;
    height: 45px;
    width: 40px;
}
/* Circles*/
}
.lll{
    width: 80%;
    margin-top: 30px;
}
.text-center{
    font-family: 'Nunito';
    color: green;
}
.textbox{
    height: 150px;
}

@media (max-width: 868px) {

    body{
    overflow-x:hidden;
    }
    input::placeholder{
        font-size: 11px;
    }
}

@media (max-width: 577px) {

    body{
    overflow-x:hidden;
    }
    input::placeholder{
        font-size: 11px;
    }
    
.lll{
    width: 100%;
    margin-top: 30px;
}
}
.boddy{
  background-image: url('D:/ReactJS/AI - Copy/ai/public/images/10plain.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parent{
  height: 320px;
  width: 100%;
 /* background-color: red; */
  display: flex;
  align-items: center;
}
.child1 .img{
  height: 300px;
  width: 50%;
  /* background-color: aquamarine; */
}
.child2{
  margin-left: 10px;
  margin-top: 190px;
  width: 50%;
  /* background-color: blueviolet; */
}
.topline{
  font-size: 19px;
  color: darkgreen;
  font-style: italic;
  font-weight: 600;
}
.textcontainertreatments{
  width: 90%;
  margin: auto;
  top: 70px;
  margin-top: 100px;
  justify-content: center;
  text-align: justify;
  font-family: 'Montserrat';
    }

.textcontainer1{
      font-weight: bolder;
      font-style: italic;
      width: 50%;
      margin: auto;
      font-family: 'Montserrat';
      line-height: 100px;
      height: 100px;
      text-align: center;
        }
.textcontainer1 p{
line-height: 1.5;
display: inline-block;
vertical-align: middle;
}

.headings{
font-size: 33px;
}
.subheadings{
font-size: 25px;
}
.topspace{
  margin-top: 10px;
}
.italic{
  font-style: italic;
  font-size: 16px;
  font-weight:bold;
}
.image1{
  width: 500px;
  height: 300px;
}

/* whatspp button starts*/
.whatsapp{
  position:relative;
  top:5px;
  height:50px;
  width:150px;
  margin:auto;
  margin-bottom: 70px;
  
  }
  .whatsappus{
  height:40px;width:140px;
  }
/* whatspp button ends*/    
ul.d {
  list-style-type: Number;
}

@media (max-width:577px){
  body{
      overflow-x: hidden;
  }
  .image1 {
    width: 350px;
    height: 200px;
}
}


@media (max-width:577px){
  body{
      overflow-x: hidden;
  }
  .image1 {
    width: 315px;
    height: 165px;
}
}
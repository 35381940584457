
.homewhatsppus1{
    position: -webkit-sticky;
    position: sticky;
    top:80%;
    left: 95%;
    height: 30px;
    width: 40px;
    opacity: 0.6;
}
.homewhatsppus1:hover{
    opacity: 1;
}
.navbar-brand{
    height: 155px;
    width: 155px;
    background-color: white;
    border-radius: 50%;
}
.navbar-brand img{
    margin-top: 25px;
    height: 155px;
    width: 155px;
}
.mobilelogo{
    display: none;
}

/*NAVBAR*/
.navbar{
    background-color: white;
    /* top:5px; */
    z-index: 100;
    height: 100px;
    }
    
    .marginleftt{
        margin-left: 100px;
    }
    .marginrightt{
        margin-right: 150px;
    }
    .nav-link{
        font-size: 20px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }
    .nav-link:hover{
        color:darkgreen;
    }
    .nav-link:visited{
        color:darkgreen;
    }
    /*NAVBAR*/

    @media (max-width:1025px){
        body{
            overflow-x: hidden;
        }
        .nav-link{
            font-size: 17px;
            font-family: 'Nunito';
            font-weight: 600;
            color: green;
        }
    }    
    
@media (max-width:992px){
    body{
        overflow-x: hidden;
    }
    /*NAVBAR*/
    .navbar{
        /* top:5px; */
        z-index: 100;
        height: 100px;
        }
    .navbar-brand{
        height: 135px;
        width: 135px;
        background-color: white;
        border-radius: 95%;
        margin-top: 43px;
    }
    .navbar-brand img{
        margin-top: 10px;
        height: 135px;
        width: 135px;
    }
    .nav-link{
        font-size: 16px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }
    .marginrightt{
        margin-right: 0px;
    }
    .navbar-toggler-icon{
        /* background-color: lightgreen; */
        
    }
    .navbar-toggler {
        color: green;
        /* border-color: green */
    }
    .navbar-collapse{
        
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .left{
        position: absolute;
        height: 590px;
        width: 100px;
        background-image: url('D:\ReactJS\AI\ai\public\images\3.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .right{
        display: none;
        position: absolute; /* relative kela ki navbar yachya khali yeta*/
        right: 0px;
        height: 100vh;
        width: 100%;
    }

    /*NAVBAR*/
}

@media (max-width:868px){
    body{
        overflow-x: hidden;
    }
    .nav-link{
        font-size: 13px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }
    .navbar-brand img{
        margin-top: -4px;
        height: 135px;
        width: 135px;
    }
    .container-fluid{
        justify-content: space-between;
    }

}

@media (max-width:768px) {
    body{
        overflow-x: hidden;
    }
    .nav-link{
        font-size: 10px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }


}

@media (max-width:684px) {
    body{
        overflow-x: hidden;
    }
    .nav-link{
        font-size: 8px;
        font-family: 'Nunito';
        font-weight: 800;
        color: green;
    }
}

@media (max-width:635px) {
    body{
        overflow-x: hidden;
    }
    .nav-link{
        font-size: 5px;
        font-family: 'Nunito';
        font-weight: 800;
        color: green;
    }
}

@media (max-width:577px){
    body{
        overflow-x: hidden;
    }
  .leaf-button {
    display: inline-block;
    padding: 15px 18px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #4CAF50;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }

  /* Leaf Effect Styles */
  .leaf-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 150%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transform: rotate(20deg);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
     }

  .leaf-button:hover::before {
    left: 150%;
  }
    .navbar-toggler{
        margin-top: 25px;
    }
    .container-fluid{
        display: flex;
        justify-content: flex-start;
        align-content: flex-end;
        flex-direction: row-reverse;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .divmobilelogo{
        height: 135px;
        width: 135px;
        width: 100%;
        display: flex;
        justify-content: center;

    }
    .mobilelogo{
        display: block;
    }
    .navbar-brand{
        display: none;
    }
    
    /*NAVBAR*/
    .navbar{
        height: auto;
    }
    .marginleftt{
        /* margin-left: 210px; */
    }
    .marginrightt{
        /* margin-right: -21px; */
    }
    .navbar-brand img {
        /* margin-top: -20px;
        height: 155px;
        width: 155px; */
        display: none;
    }
    .navbar-collapse{
        width: 100%;
        /* display: flex; */
        text-align: center;
        justify-content: center;
    }
    .nav-link{
        font-size: 16px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }
    .left{
        position: absolute;
        height: 390px;
        width: 100px;
        background-image: url('D:\ReactJS\AI\ai\public\images\3.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    /*NAVBAR*/

    .mainrightbox h1 {
        font-size: 35px;
    }

    .mainrightbox {
        /* background-color: red; */
        width: 70%;
        margin: auto;
        margin-top: 150px;
        color: white;
    }
    .homewhatsppus1{
        position: -webkit-sticky;
        position: sticky;
        top:50%;
        left: 95%;
        height: 30px;
        width: 40px;
        opacity: 0.6;
    }
}

@media (max-width:436px){
    body{
        overflow-x: hidden;
    }
    .homewhatsppus1{
        position: -webkit-sticky;
        position: sticky;
        top:40%;
        left: 95%;
        height: 30px;
        width: 40px;
        opacity: 0.6;
    }
}

@media (max-width:561px){
    body{
        overflow-x: hidden;
    }
    /*NAVBAR*/
    .navbar{
        height: auto;
    }
    .marginleftt{
        margin-left: 210px;
    }
    .marginrightt{
        /* margin-right: -41px; */
    }
    .navbar-brand img {
        margin-top: -20px;
        height: 155px;
        width: 155px;
    }
    .navbar-collapse{
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .nav-link{
        font-size: 16px;
        font-family: 'Nunito';
        font-weight: 600;
        color: green;
    }
    .left{
        position: absolute;
        height: 390px;
        width: 100px;
        background-image: url('D:\ReactJS\AI\ai\public\images\3.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    /*NAVBAR*/
}

@media (max-width:561px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 35px;
    }

    .mainrightbox {
        /* background-color: red; */
        width: 100%;
        margin: auto;
        margin-top: 150px;
        color: white;
    }   
}

@media (max-width:437px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 30px;
    }

    .mainrightbox {
        /* background-color: red; */
        width: 67%;
        margin: auto;
        margin-top: 130px;
        color: white;
    }
      
}

@media (max-width:426px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 25px;
    }

    .mainrightbox {
        /* background-color: red; */
        width: 55%;
        margin: auto;
        margin-top: 130px;
        color: white;
    }   
    .homewhatsppus1{
        position: -webkit-sticky;
        position: sticky;
        top:40%;
        left: 95%;
        height: 30px;
        width: 40px;
        opacity: 0.6;
    }
}
@media (max-width:500px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox {
        /* background-color: red; */
        
        margin: auto;
        margin-top: 130px;
        color: white;
    }
    
}

@media (max-width:380px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox {
        /* background-color: red; */
        margin: auto;
        margin-top: 110px;
        color: white;
    }
    
}
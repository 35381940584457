
.mainbox {
    height: 450px;
    width: 100%;
    /* background-color: #819171; */
    display: inline-block;
    background-image: url("/public/images/10plain.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bottomline{
    font-style: italic;
    margin-top: 25px;
    text-align: center;
    margin-left: 45%;
    font-size: 22px;
    color: green;
}
.mainrightbox {
    position: relative;
    /* background-color: red; */
    margin: auto;
    top: 150px;
    color: white;
    height: 100px;
    width: 700px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.headingtext1{
    font-size: 60px;
    margin-left: 15%;
}

.headingtext2 {
    font-size: 60px;
    margin-left: 25%;
}

.center {
    height: auto;
    width: auto;
    /* margin-top:100px; */
    margin-bottom: 60px;
    z-index: 100;
    /* background-color: red; */
}

.center .img {
    width: 80%;
}

.whatsappimage {
    margin-top: -70px;
    height: 35px;
    width: 130px;
}

.icon11 {
    margin-top: -38px;
    cursor: pointer;
    height: 35px;
}

.namefontsize {
    margin-top: 100px;
    font-size: 100px;
}


/*imageslider starts */

/* .jjback{
    margin-top: -50px;
    width: 100%;
    background-color: white;
} */
.jj {
    display: flex;
    justify-content: center;
    width: 900px;
    margin: auto;
    margin-top: 50px;
}

/*imageslider ends */

/* 360 starts*/
.text1 {
    text-align: center;
    font-size: 25px;
    font-family: 'Nunito';
}

.fimage {
    display: block;
    margin: auto;
    width: 60%;
    height: 400px;
}

/* 360 ends*/


@media (max-width:1188px){

    
}

@media (max-width:992px) {
    body {
        overflow-x: hidden;
    }

    .center {
        height: auto;
        width: auto;

        /* background-color: red; */
    }

    .center .img {
        width: 95%;
    }

    /* */
    .hh {
        width: 100%;
        object-fit: fill;
        height: 400px;
    }

    .jj {
        width: 79%;
    }

    /* */

    /* 360 starts  */
    .fimage {
        width: 60%;
    }

    /* 360 ends  */

    .namefontsize {
        margin-top: 40px;
        font-size: 40px;
    }

}


@media (max-width:768px) {
    body {
        overflow-x: hidden;
    }

    .mainbox {
        height: 330px;
        width: 100%;
        /* background-color: #819171; */
        display: inline-block;
        background-image: url("/public/images/10text.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .mainboxdiv{
        margin-top: -15px;
    }

    .mainrightbox {
        display: none;
        /* background-color: red; */
        width: 50%;
        margin: auto;
        margin-top: 150px;
        color: white;
    }
    .bottomline{
        display: none;
    }

    .mainrightbox h1 {
        font-size: 32px;
    }

    .headingtext2 {
        margin-left: 5px;
    }

    .bottomline {
        font-style: italic;
        margin-top: 13px;
        text-align: center;
        margin-left: 220px;
        font-size: 15px;
        color: green;
    }    

}
@media (max-width:684px) {
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 26px;
    }

    .headingtext2 {
        margin-left: 5px;
    }
    .bottomline {
        font-style: italic;
        margin-top: 13px;
        text-align: center;
        margin-left: 180px;
        font-size: 15px;
        color: green;
    }
}

@media (max-width:516px) {
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 22px;
    }

    .headingtext2 {
        margin-left: 5px;
    }
    .bottomline {
        font-style: italic;
        margin-top: 13px;
        text-align: center;
        margin-left: 180px;
        font-size: 13px;
        color: green;
    }
}

@media (max-width:437px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox {
        /* background-color: red; */
        width: 50%;
        margin: auto;
        margin-top: 80px;
        color: white;
    }
    .mainrightbox h1 {
        font-size: 25px;
        width: max-content;
    }

    .headingtext2 {
        margin-left: 65px;
    }
    .bottomline {
        font-style: italic;
        margin-top: 13px;
        text-align: center;
        margin-left: 150px;
        font-size: 10px;
        color: green;
    }
    .mainbox {
        height: 220px;
        width: 100%;
        /* background-color: #819171; */
        display: inline-block;
        /* background-image: url("/public/images/10.png"); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

}

@media (max-width:359px){
    body{
        overflow-x: hidden;
    }
    .mainrightbox h1 {
        font-size: 20px;
    }

    .headingtext2 {
        margin-left: 55px;
    }
    .bottomline {
        font-style: italic;
        margin-top: 13px;
        text-align: center;
        margin-left: 120px;
        font-size: 10px;
        color: green;
    }

}